/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.wp-block-audio figcaption {
  color: #555d66;
  font-size: 13px;
  text-align: center; }

.wp-block-code {
  font-family: Menlo, Consolas, monaco, monospace;
  font-size: 14px;
  color: #23282d;
  padding: 0.8em 1em;
  border: 1px solid #e2e4e7;
  border-radius: 4px; }

.wp-block-embed figcaption {
  color: #555d66;
  font-size: 13px;
  text-align: center; }

.wp-block-image figcaption {
  color: #555d66;
  font-size: 13px;
  text-align: center; }

.wp-block-preformatted pre {
  font-family: Menlo, Consolas, monaco, monospace;
  color: #23282d;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px; }
  @media (min-width: 600px) {
    .wp-block-preformatted pre {
      font-size: 14px; } }

.wp-block-pullquote {
  border-top: 4px solid #555d66;
  border-bottom: 4px solid #555d66;
  margin-bottom: 28px;
  color: #40464d; }
  .wp-block-pullquote cite,
  .wp-block-pullquote footer, .wp-block-pullquote__citation {
    color: #40464d;
    text-transform: uppercase;
    font-size: 13px;
    font-style: normal; }

.wp-block-quote {
  border-left: 4px solid #000;
  margin: 0 0 28px 0;
  padding-left: 1em; }
  .wp-block-quote cite,
  .wp-block-quote footer, .wp-block-quote__citation {
    color: #6c7781;
    font-size: 13px;
    margin-top: 1em;
    position: relative;
    font-style: normal; }
  .wp-block-quote[style*="text-align:right"], .wp-block-quote[style*="text-align: right"] {
    border-left: none;
    border-right: 4px solid #000;
    padding-left: 0;
    padding-right: 1em; }
  .wp-block-quote[style*="text-align:center"], .wp-block-quote[style*="text-align: center"] {
    border: none;
    padding-left: 0; }
  .wp-block-quote.is-style-large, .wp-block-quote.is-large {
    border: none; }

.wp-block-search .wp-block-search__label {
  font-weight: bold; }

.wp-block-group.has-background {
  padding: 20px 30px;
  margin-top: 0;
  margin-bottom: 0; }

.wp-block-separator {
  border: none;
  border-bottom: 2px solid #8f98a1;
  margin-left: auto;
  margin-right: auto; }
  .wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
    max-width: 100px; }

.wp-block-table {
  border-collapse: collapse; }
  .wp-block-table td,
  .wp-block-table th {
    padding: 0.5em;
    border: 1px solid;
    word-break: normal; }

.wp-block-video figcaption {
  color: #555d66;
  font-size: 13px;
  text-align: center; }
