@charset "UTF-8";
/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.block-editor-block-drop-zone {
  border: none;
  border-radius: 0; }
  .block-editor-block-drop-zone .components-drop-zone__content,
  .block-editor-block-drop-zone.is-dragging-over-element .components-drop-zone__content {
    display: none; }
  .block-editor-block-drop-zone.is-close-to-bottom {
    background: none;
    border-bottom: 3px solid #0085ba; }
  body.admin-color-sunrise .block-editor-block-drop-zone.is-close-to-bottom{
    border-bottom: 3px solid #d1864a; }
  body.admin-color-ocean .block-editor-block-drop-zone.is-close-to-bottom{
    border-bottom: 3px solid #a3b9a2; }
  body.admin-color-midnight .block-editor-block-drop-zone.is-close-to-bottom{
    border-bottom: 3px solid #e14d43; }
  body.admin-color-ectoplasm .block-editor-block-drop-zone.is-close-to-bottom{
    border-bottom: 3px solid #a7b656; }
  body.admin-color-coffee .block-editor-block-drop-zone.is-close-to-bottom{
    border-bottom: 3px solid #c2a68c; }
  body.admin-color-blue .block-editor-block-drop-zone.is-close-to-bottom{
    border-bottom: 3px solid #82b4cb; }
  body.admin-color-light .block-editor-block-drop-zone.is-close-to-bottom{
    border-bottom: 3px solid #0085ba; }
  .block-editor-block-drop-zone.is-close-to-top, .block-editor-block-drop-zone.is-appender.is-close-to-top, .block-editor-block-drop-zone.is-appender.is-close-to-bottom {
    background: none;
    border-top: 3px solid #0085ba;
    border-bottom: none; }
  body.admin-color-sunrise .block-editor-block-drop-zone.is-close-to-top, body.admin-color-sunrise .block-editor-block-drop-zone.is-appender.is-close-to-top, body.admin-color-sunrise .block-editor-block-drop-zone.is-appender.is-close-to-bottom{
    border-top: 3px solid #d1864a; }
  body.admin-color-ocean .block-editor-block-drop-zone.is-close-to-top, body.admin-color-ocean .block-editor-block-drop-zone.is-appender.is-close-to-top, body.admin-color-ocean .block-editor-block-drop-zone.is-appender.is-close-to-bottom{
    border-top: 3px solid #a3b9a2; }
  body.admin-color-midnight .block-editor-block-drop-zone.is-close-to-top, body.admin-color-midnight .block-editor-block-drop-zone.is-appender.is-close-to-top, body.admin-color-midnight .block-editor-block-drop-zone.is-appender.is-close-to-bottom{
    border-top: 3px solid #e14d43; }
  body.admin-color-ectoplasm .block-editor-block-drop-zone.is-close-to-top, body.admin-color-ectoplasm .block-editor-block-drop-zone.is-appender.is-close-to-top, body.admin-color-ectoplasm .block-editor-block-drop-zone.is-appender.is-close-to-bottom{
    border-top: 3px solid #a7b656; }
  body.admin-color-coffee .block-editor-block-drop-zone.is-close-to-top, body.admin-color-coffee .block-editor-block-drop-zone.is-appender.is-close-to-top, body.admin-color-coffee .block-editor-block-drop-zone.is-appender.is-close-to-bottom{
    border-top: 3px solid #c2a68c; }
  body.admin-color-blue .block-editor-block-drop-zone.is-close-to-top, body.admin-color-blue .block-editor-block-drop-zone.is-appender.is-close-to-top, body.admin-color-blue .block-editor-block-drop-zone.is-appender.is-close-to-bottom{
    border-top: 3px solid #82b4cb; }
  body.admin-color-light .block-editor-block-drop-zone.is-close-to-top, body.admin-color-light .block-editor-block-drop-zone.is-appender.is-close-to-top, body.admin-color-light .block-editor-block-drop-zone.is-appender.is-close-to-bottom{
    border-top: 3px solid #0085ba; }

.block-editor-block-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 0;
  border-radius: 4px; }
  .block-editor-block-icon.has-colors svg {
    fill: currentColor; }
  .block-editor-block-icon svg {
    min-width: 20px;
    min-height: 20px;
    max-width: 24px;
    max-height: 24px; }

.block-editor-block-inspector__no-blocks {
  display: block;
  font-size: 13px;
  background: #fff;
  padding: 32px 16px;
  text-align: center; }

.block-editor-block-inspector__card {
  display: flex;
  align-items: flex-start;
  margin: -16px;
  padding: 16px; }

.block-editor-block-inspector__card-icon {
  border: 1px solid #ccd0d4;
  padding: 7px;
  margin-right: 10px;
  height: 36px;
  width: 36px; }

.block-editor-block-inspector__card-content {
  flex-grow: 1; }

.block-editor-block-inspector__card-title {
  font-weight: 500;
  margin-bottom: 5px; }

.block-editor-block-inspector__card-description {
  font-size: 13px; }

.block-editor-block-inspector__card .block-editor-block-icon {
  margin-left: -2px;
  margin-right: 10px;
  padding: 0 3px;
  width: 36px;
  height: 24px; }

.block-editor-block-list__layout .components-draggable__clone .block-editor-block-contextual-toolbar {
  display: none !important; }

.block-editor-block-list__layout .block-editor-block-list__block.is-selected.is-dragging .block-editor-block-list__block-edit::before {
  border: none; }

.block-editor-block-list__layout .block-editor-block-list__block.is-selected.is-dragging > .block-editor-block-list__block-edit > * {
  background: #f8f9f9; }

.block-editor-block-list__layout .block-editor-block-list__block.is-selected.is-dragging > .block-editor-block-list__block-edit > * > * {
  visibility: hidden; }

.block-editor-block-list__layout .block-editor-block-list__block.is-selected.is-dragging .block-editor-block-mover,
.block-editor-block-list__layout .block-editor-block-list__block.is-selected.is-dragging .block-editor-block-contextual-toolbar {
  display: none; }

.block-editor-block-list__layout .block-editor-block-list__block.is-selected > .block-editor-block-list__block-edit .reusable-block-edit-panel * {
  z-index: 1; }

/**
 * General layout
 */
@media (min-width: 600px) {
  .block-editor-block-list__layout {
    padding-left: 46px;
    padding-right: 46px; } }

.block-editor-block-list__block .block-editor-block-list__layout {
  padding-left: 0;
  padding-right: 0;
  margin-left: -14px;
  margin-right: -14px; }

.block-editor-block-list__layout .block-editor-block-list__block {
  position: relative;
  padding-left: 14px;
  padding-right: 14px;
  overflow-wrap: break-word;
  /**
	 * Notices
	 */
  /**
	 * Block border layout
	 */ }
  @media (min-width: 600px) {
    .block-editor-block-list__layout .block-editor-block-list__block {
      padding-left: 43px;
      padding-right: 43px; } }
  .block-editor-block-list__layout .block-editor-block-list__block .components-placeholder .components-with-notices-ui {
    margin: -10px 20px 12px 20px;
    width: calc(100% - 40px); }
  .block-editor-block-list__layout .block-editor-block-list__block .components-with-notices-ui {
    margin: 0 0 12px 0;
    width: 100%; }
    .block-editor-block-list__layout .block-editor-block-list__block .components-with-notices-ui .components-notice {
      margin-left: 0;
      margin-right: 0; }
      .block-editor-block-list__layout .block-editor-block-list__block .components-with-notices-ui .components-notice .components-notice__content {
        font-size: 13px; }
  .block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__block-edit {
    position: relative; }
    .block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__block-edit::before {
      z-index: 0;
      content: "";
      position: absolute;
      border: 1px solid transparent;
      border-left: none;
      box-shadow: none;
      pointer-events: none;
      transition: border-color 0.1s linear, box-shadow 0.1s linear;
      outline: 1px solid transparent;
      right: -14px;
      left: -14px;
      top: -14px;
      bottom: -14px; }
      @media (prefers-reduced-motion: reduce) {
        .block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__block-edit::before {
          transition-duration: 0s; } }
  .block-editor-block-list__layout .block-editor-block-list__block.is-selected > .block-editor-block-list__block-edit::before {
    border-color: rgba(66, 88, 99, 0.4);
    box-shadow: inset 3px 0 0 0 #555d66; }
    .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.is-selected > .block-editor-block-list__block-edit::before {
      border-color: rgba(255, 255, 255, 0.45);
      box-shadow: inset 3px 0 0 0 #d7dade; }
    @media (min-width: 600px) {
      .block-editor-block-list__layout .block-editor-block-list__block.is-selected > .block-editor-block-list__block-edit::before {
        box-shadow: -3px 0 0 0 #555d66; }
        .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.is-selected > .block-editor-block-list__block-edit::before {
          box-shadow: -3px 0 0 0 #d7dade; } }
  .block-editor-block-list__layout .block-editor-block-list__block.is-hovered > .block-editor-block-list__block-edit::before {
    box-shadow: -3px 0 0 0 rgba(145, 151, 162, 0.25); }
    .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.is-hovered > .block-editor-block-list__block-edit::before {
      box-shadow: -3px 0 0 0 rgba(255, 255, 255, 0.25); }
  .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected) {
    opacity: 0.5;
    transition: opacity 0.1s linear; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected) {
        transition-duration: 0s; } }
    .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected):not(.is-focused) .block-editor-block-list__block, .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected).is-focused {
      opacity: 1; }

/**
 * Cross-block selection
 */

.block-editor-block-list__layout .block-editor-block-list__block ::selection {
  background-color: #b3e7fe; }

.block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected *::selection {
  background-color: transparent; }

.block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected .block-editor-block-list__block-edit::before {
  background: #b3e7fe;
  mix-blend-mode: multiply;
  top: -14px;
  bottom: -14px; }
  .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected .block-editor-block-list__block-edit::before {
    mix-blend-mode: soft-light; }

/**
 * Block styles and alignments
 */
.block-editor-block-list__layout .block-editor-block-list__block.has-warning {
  min-height: 36px; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning .block-editor-block-list__block-edit > * {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning .block-editor-block-list__block-edit .block-editor-warning {
  pointer-events: all; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning .block-editor-block-list__block-edit::before {
  border-color: rgba(145, 151, 162, 0.25);
  border-left: 1px solid rgba(145, 151, 162, 0.25); }
  .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.has-warning .block-editor-block-list__block-edit::before {
    border-color: rgba(255, 255, 255, 0.35); }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning.is-selected .editor-block-list__block-edit::before {
  border-color: rgba(66, 88, 99, 0.4);
  border-left-color: transparent; }
  .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.has-warning.is-selected .editor-block-list__block-edit::before {
    border-color: rgba(255, 255, 255, 0.45); }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning .block-editor-block-list__block-edit::after {
  content: "";
  position: absolute;
  background-color: rgba(248, 249, 249, 0.4);
  top: -14px;
  bottom: -14px;
  right: -14px;
  left: -14px; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning.is-multi-selected .block-editor-block-list__block-edit::after {
  background-color: transparent; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning.is-selected .block-editor-block-list__block-edit::after {
  bottom: 22px; }
  @media (min-width: 600px) {
    .block-editor-block-list__layout .block-editor-block-list__block.has-warning.is-selected .block-editor-block-list__block-edit::after {
      bottom: -14px; } }

.block-editor-block-list__layout .block-editor-block-list__block.is-typing .block-editor-block-list__side-inserter {
  opacity: 0;
  animation: none; }

.block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__side-inserter {
  animation: edit-post__fade-in-animation 0.2s ease-out 0s;
  animation-fill-mode: forwards; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__side-inserter {
      animation-duration: 1ms; } }

.block-editor-block-list__layout .block-editor-block-list__block.is-reusable > .block-editor-block-list__block-edit::before {
  border: 1px dashed rgba(145, 151, 162, 0.25); }
  .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.is-reusable > .block-editor-block-list__block-edit::before {
    border-color: rgba(255, 255, 255, 0.35); }

.block-editor-block-list__layout .block-editor-block-list__block.is-reusable.is-selected > .block-editor-block-list__block-edit::before {
  border-color: rgba(66, 88, 99, 0.4);
  border-left-color: transparent; }
  .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block.is-reusable.is-selected > .block-editor-block-list__block-edit::before {
    border-color: rgba(255, 255, 255, 0.45);
    border-left-color: transparent; }

.block-editor-block-list__layout .block-editor-block-list__block.is-reusable > .block-editor-block-list__block-edit .block-editor-inner-blocks.has-overlay::after {
  display: none; }

.block-editor-block-list__layout .block-editor-block-list__block.is-reusable > .block-editor-block-list__block-edit .block-editor-inner-blocks.has-overlay .block-editor-inner-blocks.has-overlay::after {
  display: block; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="left"], .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] {
  z-index: 81;
  width: 100%;
  height: 0; }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-list__block-edit, .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] .block-editor-block-list__block-edit {
    margin-top: 0; }
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-list__block-edit::before, .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] .block-editor-block-list__block-edit::before {
      content: none; }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar, .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
    margin-bottom: 1px; }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar, .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
    width: auto;
    border-bottom: 1px solid #b5bcc2;
    bottom: auto; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar {
  left: 0;
  right: auto; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
  left: auto;
  right: 0; }

@media (min-width: 600px) {
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar,
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar {
    top: 14px; } }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-list__block-edit {
  /*!rtl:begin:ignore*/
  float: left;
  margin-right: 2em;
  /*!rtl:end:ignore*/ }

@media (min-width: 600px) {
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] .block-editor-block-toolbar {
    /*!rtl:begin:ignore*/
    left: 14px;
    right: auto;
    /*!rtl:end:ignore*/ } }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] > .block-editor-block-list__block-edit {
  /*!rtl:begin:ignore*/
  float: right;
  margin-left: 2em;
  /*!rtl:end:ignore*/ }

@media (min-width: 600px) {
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] .block-editor-block-toolbar {
    /*!rtl:begin:ignore*/
    right: 14px;
    left: auto;
    /*!rtl:end:ignore*/ } }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"], .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] {
  clear: both;
  z-index: 20; }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"].is-multi-selected > .block-editor-block-mover,
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit > .block-editor-block-mover, .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"].is-multi-selected > .block-editor-block-mover,
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] > .block-editor-block-list__block-edit > .block-editor-block-mover {
    top: -44px;
    bottom: auto;
    min-height: 0;
    height: auto;
    width: auto; }
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"].is-multi-selected > .block-editor-block-mover::before,
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit > .block-editor-block-mover::before, .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"].is-multi-selected > .block-editor-block-mover::before,
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] > .block-editor-block-list__block-edit > .block-editor-block-mover::before {
      content: none; }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"].is-multi-selected > .block-editor-block-mover .block-editor-block-mover__control,
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit > .block-editor-block-mover .block-editor-block-mover__control, .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"].is-multi-selected > .block-editor-block-mover .block-editor-block-mover__control,
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] > .block-editor-block-list__block-edit > .block-editor-block-mover .block-editor-block-mover__control {
    float: left; }
  @media (min-width: 600px) {
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] .block-editor-block-toolbar, .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] .block-editor-block-toolbar {
      display: inline-flex; } }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] .block-editor-block-mover.is-visible + .block-editor-block-list__breadcrumb, .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] .block-editor-block-mover.is-visible + .block-editor-block-list__breadcrumb {
    top: -19px; }
  @media (min-width: 480px) {
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] .block-editor-block-toolbar, .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] .block-editor-block-toolbar {
      /*!rtl:begin:ignore*/
      left: 90px;
      /*!rtl:end:ignore*/ } }
  @media (min-width: 1080px) {
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] .block-editor-block-toolbar, .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] .block-editor-block-toolbar {
      /*!rtl:begin:ignore*/
      left: 14px;
      /*!rtl:end:ignore*/ } }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"].is-multi-selected > .block-editor-block-mover,
.block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"] > .block-editor-block-list__block-edit > .block-editor-block-mover {
  left: -13px; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit > .block-editor-block-list__breadcrumb {
  left: 0; }

@media (min-width: 600px) {
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] {
    margin-left: -45px;
    margin-right: -45px; } }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit {
  margin-left: -14px;
  margin-right: -14px; }
  @media (min-width: 600px) {
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit {
      margin-left: -44px;
      margin-right: -44px; } }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit figure {
    width: 100%; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit::before {
  left: 0;
  right: 0;
  border-left-width: 0;
  border-right-width: 0; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"].is-multi-selected > .block-editor-block-mover,
.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit > .block-editor-block-mover {
  left: 1px; }

.block-editor-block-list__layout .block-editor-block-list__block[data-clear="true"] {
  float: none; }

.block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-drop-zone {
  top: -4px;
  bottom: -3px;
  margin: 0 14px; }

.block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__layout .block-editor-inserter-with-shortcuts {
  display: none; }

.block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__layout .block-editor-block-list__empty-block-inserter,
.block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__layout .block-editor-default-block-appender .block-editor-inserter {
  left: auto;
  right: 8px; }

/**
 * Styles that affect inner-block containers (nested blocks).
 */
.block-editor-inner-blocks {
  /* @todo:
	The two rules above can be simplified & combined when https://github.com/WordPress/gutenberg/pull/14961 is merged,
	into the following:

	.is-selected &,
	.has-child-selected & {
		display: block;
	}
	*/ }
  .block-editor-inner-blocks .block-editor-block-list__block + .block-list-appender {
    display: none; }
    .is-selected .block-editor-inner-blocks .block-editor-block-list__block + .block-list-appender {
      display: block; }
  .block-editor-inner-blocks .block-editor-block-list__block.is-selected + .block-list-appender {
    display: block; }

/**
 * Left and right side UI; Unified toolbar on Mobile
 */
.block-editor-block-list__block.is-multi-selected > .block-editor-block-mover,
.block-editor-block-list__block > .block-editor-block-list__block-edit > .block-editor-block-mover {
  position: absolute;
  width: 30px;
  height: 100%;
  max-height: 112px; }

.block-editor-block-list__block.is-multi-selected > .block-editor-block-mover,
.block-editor-block-list__block > .block-editor-block-list__block-edit > .block-editor-block-mover {
  top: -15px; }

@media (min-width: 600px) {
  .block-editor-block-list__block.is-multi-selected .block-editor-block-mover, .block-editor-block-list__block.is-selected .block-editor-block-mover, .block-editor-block-list__block.is-hovered .block-editor-block-mover {
    z-index: 121; } }

.block-editor-block-list__block.is-multi-selected > .block-editor-block-mover,
.block-editor-block-list__block > .block-editor-block-list__block-edit > .block-editor-block-mover {
  padding-right: 2px;
  left: -45px;
  display: none; }
  @media (min-width: 600px) {
    .block-editor-block-list__block.is-multi-selected > .block-editor-block-mover,
    .block-editor-block-list__block > .block-editor-block-list__block-edit > .block-editor-block-mover {
      display: block; } }

.block-editor-block-list__block.is-multi-selected > .block-editor-block-mover {
  left: -30px; }

.block-editor-block-list__block[data-align="left"].is-selected > .block-editor-block-list__block-edit > .block-editor-block-mover, .block-editor-block-list__block[data-align="right"].is-selected > .block-editor-block-list__block-edit > .block-editor-block-mover {
  display: none; }
  @media (min-width: 600px) {
    .block-editor-block-list__block[data-align="left"].is-selected > .block-editor-block-list__block-edit > .block-editor-block-mover, .block-editor-block-list__block[data-align="right"].is-selected > .block-editor-block-list__block-edit > .block-editor-block-mover {
      display: block;
      opacity: 1;
      animation: none;
      width: 45px;
      height: auto;
      padding-bottom: 14px;
      margin-top: 0; } }

.block-editor-block-list__block[data-align="left"].is-hovered > .block-editor-block-list__block-edit > .block-editor-block-mover,
.block-editor-block-list__block[data-align="left"].is-dragging > .block-editor-block-list__block-edit > .block-editor-block-mover, .block-editor-block-list__block[data-align="right"].is-hovered > .block-editor-block-list__block-edit > .block-editor-block-mover,
.block-editor-block-list__block[data-align="right"].is-dragging > .block-editor-block-list__block-edit > .block-editor-block-mover {
  display: none; }

/**
 * Mobile unified toolbar.
 */
.block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar {
  display: flex;
  flex-direction: row;
  transform: translateY(15px);
  margin-top: 37px;
  margin-right: -14px;
  margin-left: -14px;
  border-top: 1px solid #b5bcc2;
  height: 37px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(25, 30, 35, 0.05), 0 2px 2px rgba(25, 30, 35, 0.05); }
  @media (min-width: 600px) {
    .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar {
      display: none; } }
  @media (min-width: 600px) {
    .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar {
      box-shadow: none; } }
  .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-inserter {
    position: relative;
    left: auto;
    top: auto;
    margin: 0; }
  .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-inserter__toggle,
  .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-block-mover__control {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    padding: 3px;
    margin: 0;
    justify-content: center;
    align-items: center; }
    .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-inserter__toggle .dashicon,
    .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-block-mover__control .dashicon {
      margin: auto; }
  .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-block-mover {
    display: flex;
    margin-right: auto; }
    .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-block-mover .block-editor-inserter,
    .block-editor-block-list__block .block-editor-block-list__block-mobile-toolbar .block-editor-block-mover .block-editor-block-mover__control {
      float: left; }

.block-editor-block-list__block[data-align="full"] .block-editor-block-list__block-mobile-toolbar {
  margin-left: 0;
  margin-right: 0; }

/**
 * In-Canvas Inserter
 */
.block-editor-block-list .block-editor-inserter {
  margin: 8px;
  cursor: move;
  cursor: grab; }

.block-editor-block-list__insertion-point {
  position: relative;
  z-index: 6;
  margin-top: -14px; }

.block-editor-block-list__insertion-point-indicator {
  position: absolute;
  top: calc(50% - 1px);
  height: 2px;
  left: 0;
  right: 0;
  background: #0085ba; }

body.admin-color-sunrise .block-editor-block-list__insertion-point-indicator{
  background: #d1864a; }

body.admin-color-ocean .block-editor-block-list__insertion-point-indicator{
  background: #a3b9a2; }

body.admin-color-midnight .block-editor-block-list__insertion-point-indicator{
  background: #e14d43; }

body.admin-color-ectoplasm .block-editor-block-list__insertion-point-indicator{
  background: #a7b656; }

body.admin-color-coffee .block-editor-block-list__insertion-point-indicator{
  background: #c2a68c; }

body.admin-color-blue .block-editor-block-list__insertion-point-indicator{
  background: #82b4cb; }

body.admin-color-light .block-editor-block-list__insertion-point-indicator{
  background: #0085ba; }

.block-editor-block-list__insertion-point-inserter {
  display: none;
  position: absolute;
  bottom: auto;
  left: 0;
  right: 0;
  justify-content: center;
  height: 22px;
  opacity: 0;
  transition: opacity 0.1s linear; }
  @media (min-width: 480px) {
    .block-editor-block-list__insertion-point-inserter {
      display: flex; } }
  .block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle {
    margin-top: -8px;
    border-radius: 50%;
    color: #007cba;
    background: #fff;
    height: 36px;
    width: 36px; }
    .block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle:not(:disabled):not([aria-disabled="true"]):hover {
      box-shadow: none; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-list__insertion-point-inserter {
      transition-duration: 0s; } }
  .block-editor-block-list__insertion-point-inserter:hover, .block-editor-block-list__insertion-point-inserter.is-visible {
    opacity: 1; }

.edit-post-layout:not(.has-fixed-toolbar) .is-selected > .block-editor-block-list__insertion-point > .block-editor-block-list__insertion-point-inserter,
.edit-post-layout:not(.has-fixed-toolbar) .is-focused > .block-editor-block-list__insertion-point > .block-editor-block-list__insertion-point-inserter {
  opacity: 0;
  pointer-events: none; }
  .edit-post-layout:not(.has-fixed-toolbar) .is-selected > .block-editor-block-list__insertion-point > .block-editor-block-list__insertion-point-inserter:hover, .edit-post-layout:not(.has-fixed-toolbar) .is-selected > .block-editor-block-list__insertion-point > .block-editor-block-list__insertion-point-inserter.is-visible,
  .edit-post-layout:not(.has-fixed-toolbar) .is-focused > .block-editor-block-list__insertion-point > .block-editor-block-list__insertion-point-inserter:hover,
  .edit-post-layout:not(.has-fixed-toolbar) .is-focused > .block-editor-block-list__insertion-point > .block-editor-block-list__insertion-point-inserter.is-visible {
    opacity: 1;
    pointer-events: auto; }

.block-editor-block-list__block > .block-editor-block-list__insertion-point {
  position: absolute;
  top: -16px;
  height: 28px;
  bottom: auto;
  left: 0;
  right: 0; }
  @media (min-width: 600px) {
    .block-editor-block-list__block > .block-editor-block-list__insertion-point {
      left: -1px;
      right: -1px; } }

.block-editor-block-list__block[data-align="full"] > .block-editor-block-list__insertion-point {
  left: 0;
  right: 0; }

.block-editor-block-list__block .block-editor-block-list__block-html-textarea {
  display: block;
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  resize: none;
  overflow: hidden;
  font-family: Menlo, Consolas, monaco, monospace;
  font-size: 14px;
  line-height: 150%;
  transition: padding 0.2s linear; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-list__block .block-editor-block-list__block-html-textarea {
      transition-duration: 0s; } }
  .block-editor-block-list__block .block-editor-block-list__block-html-textarea:focus {
    box-shadow: none; }

/**
 * Block Toolbar when contextual.
 */
.block-editor-block-list__block .block-editor-block-contextual-toolbar {
  z-index: 121;
  white-space: nowrap;
  text-align: left;
  pointer-events: none;
  position: absolute;
  bottom: 22px;
  left: -14px;
  right: -14px;
  border-top: 1px solid #b5bcc2; }
  .block-editor-block-list__block .block-editor-block-contextual-toolbar .components-toolbar {
    border-top: none;
    border-bottom: none; }
  @media (min-width: 600px) {
    .block-editor-block-list__block .block-editor-block-contextual-toolbar {
      border-top: none; }
      .block-editor-block-list__block .block-editor-block-contextual-toolbar .components-toolbar {
        border-top: 1px solid #b5bcc2;
        border-bottom: 1px solid #b5bcc2; } }

.block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar,
.block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
  margin-bottom: 1px;
  margin-top: -37px;
  box-shadow: -3px 0 0 0 #555d66; }
  .is-dark-theme .block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar, .is-dark-theme
  .block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
    box-shadow: -3px 0 0 0 #d7dade; }
  @media (min-width: 600px) {
    .block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar,
    .block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
      box-shadow: none; } }
  .block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar .editor-block-toolbar,
  .block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar .editor-block-toolbar {
    border-left: none; }

.block-editor-block-list__block .block-editor-block-contextual-toolbar {
  margin-left: 0;
  margin-right: 0; }
  @media (min-width: 600px) {
    .block-editor-block-list__block .block-editor-block-contextual-toolbar {
      margin-left: -15px;
      margin-right: -15px; } }

.block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar {
  /*rtl:ignore*/
  margin-right: 15px; }

.block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
  /*rtl:ignore*/
  margin-left: 15px; }

.block-editor-block-list__block .block-editor-block-contextual-toolbar > * {
  pointer-events: auto; }

.block-editor-block-list__block[data-align="full"] .block-editor-block-contextual-toolbar {
  left: 0;
  right: 0; }

@media (min-width: 600px) {
  .block-editor-block-list__block .block-editor-block-contextual-toolbar {
    bottom: auto;
    left: auto;
    right: auto;
    box-shadow: none;
    transform: translateY(-52px); }
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .block-editor-block-list__block .block-editor-block-contextual-toolbar {
        position: -webkit-sticky;
        position: sticky;
        top: 51px; } } }

.block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar {
  /*rtl:ignore*/
  float: left; }

.block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
  /*rtl:ignore*/
  float: right; }

.block-editor-block-list__block[data-align="left"] .block-editor-block-contextual-toolbar,
.block-editor-block-list__block[data-align="right"] .block-editor-block-contextual-toolbar {
  transform: translateY(-15px); }

.block-editor-block-contextual-toolbar .block-editor-block-toolbar {
  width: 100%; }
  @media (min-width: 600px) {
    .block-editor-block-contextual-toolbar .block-editor-block-toolbar {
      width: auto;
      border-right: none;
      position: absolute;
      left: 1px;
      top: 1px; } }

/**
 * Hover label
 */
.block-editor-block-list__breadcrumb {
  position: absolute;
  line-height: 1;
  z-index: 2;
  left: -17px;
  top: -31px; }
  .block-editor-block-list__breadcrumb .components-toolbar {
    border: none;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 11px;
    padding: 4px 4px;
    background: #e2e4e7;
    color: #191e23; }
    .is-dark-theme .block-editor-block-list__breadcrumb .components-toolbar {
      background: #40464d;
      color: #fff; }
    .block-editor-block-list__block:hover .block-editor-block-list__breadcrumb .components-toolbar {
      opacity: 0;
      animation: edit-post__fade-in-animation 60ms ease-out 0.5s;
      animation-fill-mode: forwards; }
      @media (prefers-reduced-motion: reduce) {
        .block-editor-block-list__block:hover .block-editor-block-list__breadcrumb .components-toolbar {
          animation-duration: 1ms; } }
  .editor-inner-blocks .block-editor-block-list__breadcrumb {
    z-index: 122; }
  [data-align="left"] .block-editor-block-list__breadcrumb {
    left: 0; }
  [data-align="right"] .block-editor-block-list__breadcrumb {
    left: auto;
    right: 0; }

.block-editor-block-list__descendant-arrow::before {
  content: "→";
  display: inline-block;
  padding: 0 4px; }
  .rtl .block-editor-block-list__descendant-arrow::before {
    content: "←"; }

@media (min-width: 600px) {
  .block-editor-block-list__block::before {
    bottom: 0;
    content: "";
    left: -28px;
    position: absolute;
    right: -28px;
    top: 0; }
  .block-editor-block-list__block .block-editor-block-list__block::before {
    left: 0;
    right: 0; }
  .block-editor-block-list__block[data-align="full"]::before {
    content: none; } }

.block-editor-block-list__block .block-editor-warning {
  z-index: 5;
  position: relative;
  margin-right: -14px;
  margin-left: -14px;
  margin-bottom: -14px;
  transform: translateY(-14px);
  padding: 10px 14px; }
  @media (min-width: 600px) {
    .block-editor-block-list__block .block-editor-warning {
      padding: 10px 14px; } }

.block-editor-block-list__block .block-list-appender {
  margin: 14px; }
  .has-background .block-editor-block-list__block .block-list-appender {
    margin: 32px 14px; }

.block-list-appender > .block-editor-inserter {
  display: block; }

/**
 * Invalid block comparison
 */
.block-editor-block-compare {
  overflow: auto;
  height: auto; }
  @media (min-width: 600px) {
    .block-editor-block-compare {
      max-height: 70%; } }

.block-editor-block-compare__wrapper {
  display: flex;
  padding-bottom: 16px; }
  .block-editor-block-compare__wrapper > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    padding: 0 16px 0 0;
    min-width: 200px; }
    .block-editor-block-compare__wrapper > div button {
      float: right; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__converted {
    border-left: 1px solid #ddd;
    padding-left: 15px; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__html {
    font-family: Menlo, Consolas, monaco, monospace;
    font-size: 12px;
    color: #23282d;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    line-height: 1.7; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__html span {
      background-color: #e6ffed;
      padding-top: 3px;
      padding-bottom: 3px; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__html span.block-editor-block-compare__added {
      background-color: #acf2bd; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__html span.block-editor-block-compare__removed {
      background-color: #d94f4f; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__preview {
    padding: 0;
    padding-top: 14px; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__preview p {
      font-size: 12px;
      margin-top: 0; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__action {
    margin-top: 14px; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__heading {
    font-size: 1em;
    font-weight: 400;
    margin: 0.67em 0; }

.block-editor-block-mover {
  min-height: 56px;
  opacity: 0; }
  .block-editor-block-mover.is-visible {
    animation: edit-post__fade-in-animation 0.2s ease-out 0s;
    animation-fill-mode: forwards; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-block-mover.is-visible {
        animation-duration: 1ms; } }
  @media (min-width: 600px) {
    .block-editor-block-list__block:not([data-align="wide"]):not([data-align="full"]) .block-editor-block-mover {
      margin-top: -8px; } }

.block-editor-block-mover__control {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  width: 28px;
  height: 24px;
  color: rgba(14, 28, 46, 0.62); }
  .block-editor-block-mover__control svg {
    width: 28px;
    height: 24px;
    padding: 2px 5px; }
  .is-dark-theme .block-editor-block-mover__control {
    color: rgba(255, 255, 255, 0.65); }
  .is-dark-theme .wp-block .wp-block .block-editor-block-mover__control,
  .wp-block .is-dark-theme .wp-block .block-editor-block-mover__control {
    color: rgba(14, 28, 46, 0.62); }
  .block-editor-block-mover__control[aria-disabled="true"] {
    cursor: default;
    pointer-events: none;
    color: rgba(130, 148, 147, 0.15); }
    .is-dark-theme .block-editor-block-mover__control[aria-disabled="true"] {
      color: rgba(255, 255, 255, 0.2); }

.block-editor-block-mover__control-drag-handle {
  cursor: move;
  cursor: grab;
  fill: currentColor;
  border-radius: 4px; }
  .block-editor-block-mover__control-drag-handle, .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover, .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active, .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):focus {
    box-shadow: none;
    background: none;
    color: rgba(10, 24, 41, 0.7); }
    .is-dark-theme .block-editor-block-mover__control-drag-handle, .is-dark-theme .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover, .is-dark-theme .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active, .is-dark-theme .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):focus {
      color: rgba(255, 255, 255, 0.75); }
    .is-dark-theme .wp-block .wp-block .block-editor-block-mover__control-drag-handle,
    .wp-block .is-dark-theme .wp-block .block-editor-block-mover__control-drag-handle, .is-dark-theme .wp-block .wp-block .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover,
    .wp-block .is-dark-theme .wp-block .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover, .is-dark-theme .wp-block .wp-block .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active,
    .wp-block .is-dark-theme .wp-block .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active, .is-dark-theme .wp-block .wp-block .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):focus,
    .wp-block .is-dark-theme .wp-block .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):focus {
      color: rgba(10, 24, 41, 0.7); }
  .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active {
    cursor: grabbing; }

.block-editor-block-mover__description {
  display: none; }

@media (min-width: 600px) {
  .block-editor-block-list__layout [data-align="right"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default),
  .block-editor-block-list__layout [data-align="left"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default),
  .block-editor-block-list__layout .block-editor-block-list__layout .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default), .block-editor-block-list__layout [data-align="right"]
  .block-editor-block-mover__control,
  .block-editor-block-list__layout [data-align="left"]
  .block-editor-block-mover__control,
  .block-editor-block-list__layout .block-editor-block-list__layout
  .block-editor-block-mover__control {
    background: #fff;
    box-shadow: inset 0 0 0 1px #e2e4e7; }
    .block-editor-block-list__layout [data-align="right"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):nth-child(-n+2),
    .block-editor-block-list__layout [data-align="left"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):nth-child(-n+2),
    .block-editor-block-list__layout .block-editor-block-list__layout .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):nth-child(-n+2), .block-editor-block-list__layout [data-align="right"]
    .block-editor-block-mover__control:nth-child(-n+2),
    .block-editor-block-list__layout [data-align="left"]
    .block-editor-block-mover__control:nth-child(-n+2),
    .block-editor-block-list__layout .block-editor-block-list__layout
    .block-editor-block-mover__control:nth-child(-n+2) {
      margin-bottom: -1px; }
    .block-editor-block-list__layout [data-align="right"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover, .block-editor-block-list__layout [data-align="right"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active, .block-editor-block-list__layout [data-align="right"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):focus,
    .block-editor-block-list__layout [data-align="left"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover,
    .block-editor-block-list__layout [data-align="left"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active,
    .block-editor-block-list__layout [data-align="left"] .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):focus,
    .block-editor-block-list__layout .block-editor-block-list__layout .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover,
    .block-editor-block-list__layout .block-editor-block-list__layout .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):active,
    .block-editor-block-list__layout .block-editor-block-list__layout .block-editor-block-mover__control-drag-handle:not(:disabled):not([aria-disabled="true"]):not(.is-default):focus, .block-editor-block-list__layout [data-align="right"]
    .block-editor-block-mover__control:hover, .block-editor-block-list__layout [data-align="right"]
    .block-editor-block-mover__control:active, .block-editor-block-list__layout [data-align="right"]
    .block-editor-block-mover__control:focus,
    .block-editor-block-list__layout [data-align="left"]
    .block-editor-block-mover__control:hover,
    .block-editor-block-list__layout [data-align="left"]
    .block-editor-block-mover__control:active,
    .block-editor-block-list__layout [data-align="left"]
    .block-editor-block-mover__control:focus,
    .block-editor-block-list__layout .block-editor-block-list__layout
    .block-editor-block-mover__control:hover,
    .block-editor-block-list__layout .block-editor-block-list__layout
    .block-editor-block-mover__control:active,
    .block-editor-block-list__layout .block-editor-block-list__layout
    .block-editor-block-mover__control:focus {
      z-index: 1; } }

.block-editor-block-navigation__container {
  padding: 7px; }

.block-editor-block-navigation__label {
  margin: 0 0 8px;
  color: #6c7781; }

.block-editor-block-navigation__list,
.block-editor-block-navigation__paragraph {
  padding: 0;
  margin: 0; }

.block-editor-block-navigation__list .block-editor-block-navigation__list {
  margin-top: 2px;
  border-left: 2px solid #a2aab2;
  margin-left: 1em; }
  .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__list {
    margin-left: 1.5em; }
  .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__item {
    position: relative; }
    .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__item::before {
      position: absolute;
      left: 0;
      background: #a2aab2;
      width: 0.5em;
      height: 2px;
      content: "";
      top: calc(50% - 1px); }
  .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__item-button {
    margin-left: 0.8em;
    width: calc(100% - 0.8em); }
  .block-editor-block-navigation__list .block-editor-block-navigation__list > li:last-child {
    position: relative; }
    .block-editor-block-navigation__list .block-editor-block-navigation__list > li:last-child::after {
      position: absolute;
      content: "";
      background: #fff;
      top: 19px;
      bottom: 0;
      left: -2px;
      width: 2px; }

.block-editor-block-navigation__item-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px;
  text-align: left;
  color: #40464d;
  border-radius: 4px; }
  .block-editor-block-navigation__item-button .block-editor-block-icon {
    margin-right: 6px; }
  .block-editor-block-navigation__item-button:hover:not(:disabled):not([aria-disabled="true"]) {
    color: #191e23;
    border: none;
    box-shadow: none;
    background: #f3f4f5; }
  .block-editor-block-navigation__item-button:focus:not(:disabled):not([aria-disabled="true"]) {
    color: #191e23;
    border: none;
    box-shadow: none;
    outline-offset: -2px;
    outline: 1px dotted #555d66; }
  .block-editor-block-navigation__item-button.is-selected, .block-editor-block-navigation__item-button.is-selected:focus {
    color: #32373c;
    background: #edeff0; }

.block-editor-block-preview {
  pointer-events: none;
  padding: 10px;
  overflow: hidden;
  display: none; }
  @media (min-width: 782px) {
    .block-editor-block-preview {
      display: block; } }
  .block-editor-block-preview .block-editor-block-preview__content {
    padding: 14px;
    border: 1px solid #e2e4e7;
    font-family: "Noto Serif", serif; }
    .block-editor-block-preview .block-editor-block-preview__content > div {
      transform: scale(0.9);
      transform-origin: center top;
      font-family: "Noto Serif", serif; }
    .block-editor-block-preview .block-editor-block-preview__content > div section {
      height: auto; }
    .block-editor-block-preview .block-editor-block-preview__content > .reusable-block-indicator {
      display: none; }

.block-editor-block-preview__content .block-editor-block-list__layout,
.block-editor-block-preview__content .block-editor-block-list__block {
  padding: 0; }

.block-editor-block-preview__content .editor-block-list__block-edit [data-block] {
  margin-top: 0; }

.block-editor-block-preview__title {
  margin-bottom: 10px;
  color: #6c7781; }

.block-editor-block-settings-menu__content {
  padding: 0; }

.block-editor-block-settings-menu__toggle .dashicon {
  transform: rotate(90deg); }

.block-editor-block-styles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.block-editor-block-styles__item {
  width: calc(50% - 4px);
  margin: 4px 0;
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  padding: 6px; }
  .block-editor-block-styles__item:focus {
    color: #191e23;
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #00a0d2;
    outline: 2px solid transparent;
    outline-offset: -2px; }
  .block-editor-block-styles__item:hover {
    background: #f3f4f5;
    color: #191e23; }
  .block-editor-block-styles__item.is-active {
    color: #191e23;
    box-shadow: inset 0 0 0 2px #555d66;
    outline: 2px solid transparent;
    outline-offset: -2px; }
    .block-editor-block-styles__item.is-active:focus {
      color: #191e23;
      box-shadow: 0 0 0 1px #fff, 0 0 0 3px #00a0d2, inset 0 0 0 2px #555d66;
      outline: 4px solid transparent;
      outline-offset: -4px; }

.block-editor-block-styles__item-preview {
  outline: 1px solid transparent;
  border: 1px solid rgba(25, 30, 35, 0.2);
  overflow: hidden;
  padding: 0;
  text-align: initial;
  border-radius: 4px;
  display: flex;
  height: 60px;
  background: #fff; }
  .block-editor-block-styles__item-preview .block-editor-block-preview__content {
    transform: scale(0.7);
    transform-origin: center center;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: visible;
    min-height: auto; }

.block-editor-block-styles__item-label {
  text-align: center;
  padding: 4px 2px; }

.block-editor-block-switcher {
  position: relative;
  height: 36px; }

.components-icon-button.block-editor-block-switcher__toggle,
.components-icon-button.block-editor-block-switcher__no-switcher-icon {
  margin: 0;
  display: block;
  height: 36px;
  padding: 3px; }

.components-icon-button.block-editor-block-switcher__no-switcher-icon {
  width: 48px; }
  .components-icon-button.block-editor-block-switcher__no-switcher-icon .block-editor-block-icon {
    margin-right: auto;
    margin-left: auto; }

.components-button.block-editor-block-switcher__no-switcher-icon:disabled {
  border-radius: 0;
  opacity: 0.84; }
  .components-button.block-editor-block-switcher__no-switcher-icon:disabled .block-editor-block-icon.has-colors {
    color: #555d66 !important;
    background: #f3f4f5 !important; }

.components-icon-button.block-editor-block-switcher__toggle {
  width: auto; }
  .components-icon-button.block-editor-block-switcher__toggle:active, .components-icon-button.block-editor-block-switcher__toggle:not(:disabled):not([aria-disabled="true"]):hover, .components-icon-button.block-editor-block-switcher__toggle:not([aria-disabled="true"]):focus {
    outline: none;
    box-shadow: none;
    background: none;
    border: none; }
  .components-icon-button.block-editor-block-switcher__toggle .block-editor-block-icon,
  .components-icon-button.block-editor-block-switcher__toggle .block-editor-block-switcher__transform {
    width: 42px;
    height: 30px;
    position: relative;
    margin: 0 auto;
    padding: 3px;
    display: flex;
    align-items: center;
    transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }
    @media (prefers-reduced-motion: reduce) {
      .components-icon-button.block-editor-block-switcher__toggle .block-editor-block-icon,
      .components-icon-button.block-editor-block-switcher__toggle .block-editor-block-switcher__transform {
        transition-duration: 0s; } }
  .components-icon-button.block-editor-block-switcher__toggle .block-editor-block-icon::after {
    content: "";
    pointer-events: none;
    display: block;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid;
    margin-left: 4px;
    margin-right: 2px; }
  .components-icon-button.block-editor-block-switcher__toggle .block-editor-block-switcher__transform {
    margin-top: 6px;
    border-radius: 4px; }
  .components-icon-button.block-editor-block-switcher__toggle[aria-expanded="true"] .block-editor-block-icon,
  .components-icon-button.block-editor-block-switcher__toggle[aria-expanded="true"] .block-editor-block-switcher__transform,
  .components-icon-button.block-editor-block-switcher__toggle:not(:disabled):hover .block-editor-block-icon,
  .components-icon-button.block-editor-block-switcher__toggle:not(:disabled):hover .block-editor-block-switcher__transform,
  .components-icon-button.block-editor-block-switcher__toggle:not(:disabled):focus .block-editor-block-icon,
  .components-icon-button.block-editor-block-switcher__toggle:not(:disabled):focus .block-editor-block-switcher__transform {
    transform: translateY(-36px); }
  .components-icon-button.block-editor-block-switcher__toggle:not(:disabled):focus .block-editor-block-icon,
  .components-icon-button.block-editor-block-switcher__toggle:not(:disabled):focus .block-editor-block-switcher__transform {
    box-shadow: inset 0 0 0 1px #555d66, inset 0 0 0 2px #fff;
    outline: 2px solid transparent;
    outline-offset: -2px; }

.components-popover:not(.is-mobile).block-editor-block-switcher__popover .components-popover__content {
  min-width: 300px;
  max-width: 340px; }

@media (min-width: 782px) {
  .block-editor-block-switcher__popover .components-popover__content {
    position: relative; }
    .block-editor-block-switcher__popover .components-popover__content .block-editor-block-preview {
      border: 1px solid #e2e4e7;
      box-shadow: 0 3px 30px rgba(25, 30, 35, 0.1);
      background: #fff;
      position: absolute;
      left: 100%;
      top: -1px;
      bottom: -1px;
      width: 300px;
      height: auto; } }

.block-editor-block-switcher__popover .components-popover__content .components-panel__body {
  border: 0;
  position: relative;
  z-index: 1; }

.block-editor-block-switcher__popover .components-popover__content .components-panel__body + .components-panel__body {
  border-top: 1px solid #e2e4e7; }

.block-editor-block-switcher__popover:not(.is-mobile) > .components-popover__content {
  overflow-y: visible; }

.block-editor-block-switcher__popover .block-editor-block-styles {
  margin: 0 -3px; }

.block-editor-block-switcher__popover .block-editor-block-types-list {
  margin: 8px -8px -8px; }

.block-editor-block-toolbar {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  position: relative;
  border-left: 1px solid #b5bcc2;
  transition: border-color 0.1s linear, box-shadow 0.1s linear; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-toolbar {
      transition-duration: 0s; } }
  @media (min-width: 600px) {
    .block-editor-block-toolbar {
      overflow: inherit;
      border-left: none;
      box-shadow: -3px 0 0 0 #555d66; }
      .is-dark-theme .block-editor-block-toolbar {
        box-shadow: -3px 0 0 0 #d7dade; } }
  .block-editor-block-toolbar .components-toolbar {
    border: 0;
    border-top: 1px solid #b5bcc2;
    border-bottom: 1px solid #b5bcc2;
    border-right: 1px solid #b5bcc2; }
  .has-fixed-toolbar .block-editor-block-toolbar {
    box-shadow: none;
    border-left: 1px solid #e2e4e7; }
    .has-fixed-toolbar .block-editor-block-toolbar .components-toolbar {
      border-color: #e2e4e7; }

.block-editor-block-toolbar__slot {
  display: inline-flex; }

.block-editor-block-types-list {
  list-style: none;
  padding: 4px 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap; }

.block-editor-button-block-appender {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21px;
  outline: 1px dashed #8d96a0;
  width: 100%;
  color: #555d66;
  background: rgba(237, 239, 240, 0.8); }
  .block-editor-button-block-appender:hover, .block-editor-button-block-appender:focus {
    outline: 1px dashed #555d66;
    color: #191e23; }
  .block-editor-button-block-appender:active {
    outline: 1px dashed #191e23;
    color: #191e23; }
  .is-dark-theme .block-editor-button-block-appender {
    background: rgba(50, 55, 60, 0.7);
    color: #f8f9f9; }
    .is-dark-theme .block-editor-button-block-appender:hover, .is-dark-theme .block-editor-button-block-appender:focus {
      outline: 1px dashed #fff; }

.block-editor-color-palette-control__color-palette {
  display: inline-block;
  margin-top: 0.6rem; }

.block-editor-contrast-checker > .components-notice {
  margin: 0; }

.block-editor-default-block-appender {
  clear: both; }
  .block-editor-default-block-appender textarea.block-editor-default-block-appender__content {
    font-family: "Noto Serif", serif;
    font-size: 16px;
    border: none;
    background: none;
    box-shadow: none;
    display: block;
    cursor: text;
    width: 100%;
    outline: 1px solid transparent;
    transition: 0.2s outline;
    resize: none;
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 0 50px 0 14px;
    color: rgba(14, 28, 46, 0.62); }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-default-block-appender textarea.block-editor-default-block-appender__content {
        transition-duration: 0s; } }
    .is-dark-theme .block-editor-default-block-appender textarea.block-editor-default-block-appender__content {
      color: rgba(255, 255, 255, 0.65); }
  .block-editor-default-block-appender:hover .block-editor-inserter-with-shortcuts {
    animation: edit-post__fade-in-animation 0.2s ease-out 0s;
    animation-fill-mode: forwards; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-default-block-appender:hover .block-editor-inserter-with-shortcuts {
        animation-duration: 1ms; } }
  .block-editor-default-block-appender .components-drop-zone__content-icon {
    display: none; }

.block-editor-block-list__empty-block-inserter,
.block-editor-default-block-appender .block-editor-inserter,
.block-editor-inserter-with-shortcuts {
  position: absolute;
  top: 0; }
  .block-editor-block-list__empty-block-inserter .components-icon-button,
  .block-editor-default-block-appender .block-editor-inserter .components-icon-button,
  .block-editor-inserter-with-shortcuts .components-icon-button {
    width: 28px;
    height: 28px;
    margin-right: 12px;
    padding: 0; }
  .block-editor-block-list__empty-block-inserter .block-editor-block-icon,
  .block-editor-default-block-appender .block-editor-inserter .block-editor-block-icon,
  .block-editor-inserter-with-shortcuts .block-editor-block-icon {
    margin: auto; }
  .block-editor-block-list__empty-block-inserter .components-icon-button svg,
  .block-editor-default-block-appender .block-editor-inserter .components-icon-button svg,
  .block-editor-inserter-with-shortcuts .components-icon-button svg {
    display: block;
    margin: auto; }
  .block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle,
  .block-editor-default-block-appender .block-editor-inserter .block-editor-inserter__toggle,
  .block-editor-inserter-with-shortcuts .block-editor-inserter__toggle {
    margin-right: 0; }
    .block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover,
    .block-editor-default-block-appender .block-editor-inserter .block-editor-inserter__toggle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover,
    .block-editor-inserter-with-shortcuts .block-editor-inserter__toggle:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover {
      box-shadow: none; }

.block-editor-block-list__empty-block-inserter,
.block-editor-default-block-appender .block-editor-inserter {
  right: 8px; }
  @media (min-width: 600px) {
    .block-editor-block-list__empty-block-inserter,
    .block-editor-default-block-appender .block-editor-inserter {
      display: flex;
      align-items: center;
      height: 100%;
      left: -44px;
      right: auto; } }
  .block-editor-block-list__empty-block-inserter:disabled,
  .block-editor-default-block-appender .block-editor-inserter:disabled {
    display: none; }
  .block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle,
  .block-editor-default-block-appender .block-editor-inserter .block-editor-inserter__toggle {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 0; }
    .block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle:not(:hover),
    .block-editor-default-block-appender .block-editor-inserter .block-editor-inserter__toggle:not(:hover) {
      color: rgba(10, 24, 41, 0.7); }
      .is-dark-theme .block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle:not(:hover), .is-dark-theme
      .block-editor-default-block-appender .block-editor-inserter .block-editor-inserter__toggle:not(:hover) {
        color: rgba(255, 255, 255, 0.75); }

.block-editor-block-list__side-inserter .block-editor-inserter-with-shortcuts,
.block-editor-default-block-appender .block-editor-inserter-with-shortcuts {
  right: 14px;
  display: none;
  z-index: 5; }
  @media (min-width: 600px) {
    .block-editor-block-list__side-inserter .block-editor-inserter-with-shortcuts,
    .block-editor-default-block-appender .block-editor-inserter-with-shortcuts {
      display: flex;
      align-items: center;
      height: 100%;
      right: 0; } }

.block-editor__container .components-popover.components-font-size-picker__dropdown-content.is-bottom {
  z-index: 100001; }

.block-editor-inner-blocks.has-overlay::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -14px;
  bottom: -14px;
  left: -14px;
  z-index: 120; }

[data-align="full"] > .editor-block-list__block-edit > [data-block] .has-overlay::after {
  right: 0;
  left: 0; }

.block-editor-inner-blocks__template-picker .components-placeholder__instructions {
  margin-bottom: 0; }

.block-editor-inner-blocks__template-picker .components-placeholder__fieldset {
  flex-direction: column; }

.block-editor-inner-blocks__template-picker.has-many-options .components-placeholder__fieldset {
  max-width: 90%; }

.block-editor-inner-blocks__template-picker-options.block-editor-inner-blocks__template-picker-options {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 4px 0;
  list-style: none; }
  .block-editor-inner-blocks__template-picker-options.block-editor-inner-blocks__template-picker-options > li {
    list-style: none;
    margin: 8px;
    flex-shrink: 1;
    max-width: 100px; }
  .block-editor-inner-blocks__template-picker-options.block-editor-inner-blocks__template-picker-options .block-editor-inner-blocks__template-picker-option {
    padding: 8px; }

.block-editor-inner-blocks__template-picker-option {
  width: 100%; }
  .block-editor-inner-blocks__template-picker-option.components-icon-button {
    justify-content: center; }
    .block-editor-inner-blocks__template-picker-option.components-icon-button.is-default {
      background-color: #fff; }
  .block-editor-inner-blocks__template-picker-option.components-button {
    height: auto;
    padding: 0; }
  .block-editor-inner-blocks__template-picker-option::before {
    content: "";
    padding-bottom: 100%; }
  .block-editor-inner-blocks__template-picker-option:first-child {
    margin-left: 0; }
  .block-editor-inner-blocks__template-picker-option:last-child {
    margin-right: 0; }

.block-editor-inserter-with-shortcuts {
  display: flex;
  align-items: center; }
  .block-editor-inserter-with-shortcuts .components-icon-button {
    border-radius: 4px; }
    .block-editor-inserter-with-shortcuts .components-icon-button svg:not(.dashicon) {
      height: 24px;
      width: 24px; }

.block-editor-inserter-with-shortcuts__block {
  margin-right: 4px;
  width: 36px;
  height: 36px;
  padding-top: 8px;
  color: rgba(10, 24, 41, 0.7); }
  .is-dark-theme .block-editor-inserter-with-shortcuts__block {
    color: rgba(255, 255, 255, 0.75); }

.block-editor-inserter {
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  line-height: 1.4; }
  @media (min-width: 782px) {
    .block-editor-inserter {
      position: relative; } }

@media (min-width: 782px) {
  .block-editor-inserter__popover:not(.is-mobile) > .components-popover__content {
    overflow-y: visible;
    height: 432px; } }

.block-editor-inserter__toggle {
  display: inline-flex;
  align-items: center;
  color: #555d66;
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  transition: color 0.2s ease; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-inserter__toggle {
      transition-duration: 0s; } }

.block-editor-inserter__menu {
  width: auto;
  display: flex;
  flex-direction: column;
  height: 100%; }
  @media (min-width: 782px) {
    .block-editor-inserter__menu {
      width: 400px;
      position: relative; }
      .block-editor-inserter__menu .block-editor-block-preview {
        border: 1px solid #e2e4e7;
        box-shadow: 0 3px 30px rgba(25, 30, 35, 0.1);
        background: #fff;
        position: absolute;
        left: 100%;
        top: -1px;
        bottom: -1px;
        width: 300px; } }

.block-editor-inserter__inline-elements {
  margin-top: -1px; }

.block-editor-inserter__menu.is-bottom::after {
  border-bottom-color: #fff; }

.components-popover input[type="search"].block-editor-inserter__search {
  display: block;
  margin: 16px;
  padding: 11px 16px;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px; }
  @media (min-width: 600px) {
    .components-popover input[type="search"].block-editor-inserter__search {
      font-size: 13px; } }
  .components-popover input[type="search"].block-editor-inserter__search:focus {
    color: #191e23;
    border-color: #007cba;
    box-shadow: 0 0 0 1px #007cba;
    outline: 2px solid transparent;
    outline-offset: -2px; }

.block-editor-inserter__results {
  flex-grow: 1;
  overflow: auto;
  position: relative;
  z-index: 1;
  padding: 0 16px 16px 16px; }
  .block-editor-inserter__results:focus {
    outline: 1px dotted #555d66; }
  @media (min-width: 782px) {
    .block-editor-inserter__results {
      height: 394px; } }
  .block-editor-inserter__results [role="presentation"] + .components-panel__body {
    border-top: none; }

.block-editor-inserter__popover .block-editor-block-types-list {
  margin: 0 -8px; }

.block-editor-inserter__reusable-blocks-panel {
  position: relative;
  text-align: right; }

.block-editor-inserter__manage-reusable-blocks {
  margin: 16px 0 0 16px; }

.block-editor-inserter__no-results {
  font-style: italic;
  padding: 24px;
  text-align: center; }

.block-editor-inserter__child-blocks {
  padding: 0 16px; }

.block-editor-inserter__parent-block-header {
  display: flex;
  align-items: center; }
  .block-editor-inserter__parent-block-header h2 {
    font-size: 13px; }
  .block-editor-inserter__parent-block-header .block-editor-block-icon {
    margin-right: 8px; }

.block-editor-block-types-list__list-item {
  display: block;
  width: 33.33%;
  padding: 0 4px;
  margin: 0 0 12px; }

.block-editor-block-types-list__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  color: #32373c;
  padding: 0;
  align-items: stretch;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  word-break: break-word;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.05s ease-in-out;
  position: relative; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-types-list__item {
      transition-duration: 0s; } }
  .block-editor-block-types-list__item:disabled {
    opacity: 0.6;
    cursor: default; }
  .block-editor-block-types-list__item:not(:disabled):hover::before {
    content: "";
    display: block;
    background: #f3f4f5;
    color: #191e23;
    position: absolute;
    z-index: -1;
    border-radius: 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .block-editor-block-types-list__item:not(:disabled):hover .block-editor-block-types-list__item-icon,
  .block-editor-block-types-list__item:not(:disabled):hover .block-editor-block-types-list__item-title {
    color: inherit; }
  .block-editor-block-types-list__item:not(:disabled):active, .block-editor-block-types-list__item:not(:disabled):focus {
    position: relative;
    outline: none;
    color: #191e23;
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #00a0d2;
    outline: 2px solid transparent;
    outline-offset: -2px; }
    .block-editor-block-types-list__item:not(:disabled):active .block-editor-block-types-list__item-icon,
    .block-editor-block-types-list__item:not(:disabled):active .block-editor-block-types-list__item-title, .block-editor-block-types-list__item:not(:disabled):focus .block-editor-block-types-list__item-icon,
    .block-editor-block-types-list__item:not(:disabled):focus .block-editor-block-types-list__item-title {
      color: inherit; }
  .block-editor-block-types-list__item:not(:disabled).is-active {
    color: #191e23;
    box-shadow: inset 0 0 0 2px #555d66;
    outline: 2px solid transparent;
    outline-offset: -2px; }
    .block-editor-block-types-list__item:not(:disabled).is-active:focus {
      color: #191e23;
      box-shadow: 0 0 0 1px #fff, 0 0 0 3px #00a0d2, inset 0 0 0 2px #555d66;
      outline: 4px solid transparent;
      outline-offset: -4px; }

.block-editor-block-types-list__item-icon {
  padding: 12px 20px;
  border-radius: 4px;
  color: #555d66;
  transition: all 0.05s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-types-list__item-icon {
      transition-duration: 0s; } }
  .block-editor-block-types-list__item-icon .block-editor-block-icon {
    margin-left: auto;
    margin-right: auto; }
  .block-editor-block-types-list__item-icon svg {
    transition: all 0.15s ease-out; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-block-types-list__item-icon svg {
        transition-duration: 0s; } }

.block-editor-block-types-list__item-title {
  padding: 4px 2px 8px; }

.block-editor-block-types-list__item-has-children .block-editor-block-types-list__item-icon {
  background: #fff;
  margin-right: 3px;
  margin-bottom: 6px;
  padding: 9px 20px 9px;
  position: relative;
  top: -2px;
  left: -2px;
  box-shadow: 0 0 0 1px #e2e4e7; }

.block-editor-block-types-list__item-has-children .block-editor-block-types-list__item-icon-stack {
  display: block;
  background: #fff;
  box-shadow: 0 0 0 1px #e2e4e7;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  bottom: -6px;
  right: -6px;
  border-radius: 4px; }

.block-editor-media-placeholder__url-input-container {
  width: 100%; }
  .block-editor-media-placeholder__url-input-container .block-editor-media-placeholder__button {
    margin-bottom: 0; }

.block-editor-media-placeholder__url-input-form {
  display: flex; }
  .block-editor-media-placeholder__url-input-form input[type="url"].block-editor-media-placeholder__url-input-field {
    width: 100%;
    flex-grow: 1;
    border: none;
    border-radius: 0;
    margin: 2px; }
    @media (min-width: 600px) {
      .block-editor-media-placeholder__url-input-form input[type="url"].block-editor-media-placeholder__url-input-field {
        width: 300px; } }

.block-editor-media-placeholder__url-input-submit-button {
  flex-shrink: 1; }

.block-editor-media-placeholder__button {
  margin-bottom: 0.5rem; }
  .block-editor-media-placeholder__button .dashicon {
    vertical-align: middle;
    margin-bottom: 3px; }
  .block-editor-media-placeholder__button:hover {
    color: #23282d; }

.block-editor-media-placeholder__cancel-button.is-link {
  margin: 1em;
  display: block; }

.components-form-file-upload .block-editor-media-placeholder__button {
  margin-right: 4px; }

.block-editor-media-placeholder.is-appender {
  min-height: 100px;
  outline: 1px dashed #8d96a0; }
  .block-editor-media-placeholder.is-appender:hover {
    outline: 1px dashed #555d66;
    cursor: pointer; }
  .is-dark-theme .block-editor-media-placeholder.is-appender:hover {
    outline: 1px dashed #fff; }
  .block-editor-media-placeholder.is-appender .block-editor-media-placeholder__upload-button {
    margin-right: 4px; }
    .block-editor-media-placeholder.is-appender .block-editor-media-placeholder__upload-button.components-button:hover, .block-editor-media-placeholder.is-appender .block-editor-media-placeholder__upload-button.components-button:focus {
      box-shadow: none;
      border: 1px solid #555d66; }

.block-editor-multi-selection-inspector__card {
  display: flex;
  align-items: flex-start;
  margin: -16px;
  padding: 16px; }

.block-editor-multi-selection-inspector__card-content {
  flex-grow: 1; }

.block-editor-multi-selection-inspector__card-title {
  font-weight: 500;
  margin-bottom: 5px; }

.block-editor-multi-selection-inspector__card-description {
  font-size: 13px; }

.block-editor-multi-selection-inspector__card .block-editor-block-icon {
  margin-left: -2px;
  margin-right: 10px;
  padding: 0 3px;
  width: 36px;
  height: 24px; }

.block-editor-panel-color-settings .component-color-indicator {
  vertical-align: text-bottom; }

.block-editor-panel-color-settings__panel-title .component-color-indicator {
  display: inline-block; }

.block-editor-panel-color-settings.is-opened .block-editor-panel-color-settings__panel-title .component-color-indicator {
  display: none; }

.block-editor .block-editor-plain-text {
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%; }

.block-editor-format-toolbar {
  display: flex;
  flex-shrink: 0; }

.block-editor-format-toolbar__selection-position {
  position: absolute;
  transform: translateX(-50%); }

.block-editor-format-toolbar .components-dropdown-menu__toggle .components-dropdown-menu__indicator::after {
  margin: 7px; }

.block-editor-rich-text {
  position: relative; }

.block-editor-rich-text__editable > p:first-child {
  margin-top: 0; }

.block-editor-rich-text__editable a {
  color: #007fac; }

.block-editor-rich-text__editable code {
  padding: 2px;
  border-radius: 2px;
  color: #23282d;
  background: #f3f4f5;
  font-family: Menlo, Consolas, monaco, monospace;
  font-size: inherit; }
  .is-multi-selected .block-editor-rich-text__editable code {
    background: #67cffd; }

.block-editor-rich-text__editable:focus {
  outline: none; }
  .block-editor-rich-text__editable:focus *[data-rich-text-format-boundary] {
    border-radius: 2px; }

.block-editor-rich-text__editable [data-rich-text-placeholder]::after {
  content: attr(data-rich-text-placeholder);
  pointer-events: none;
  opacity: 0.62; }

.block-editor-rich-text__editable.is-selected [data-rich-text-placeholder]::after {
  display: none; }

figcaption.block-editor-rich-text__editable [data-rich-text-placeholder]::before {
  opacity: 0.8; }

.block-editor-rich-text__inline-toolbar {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -40px;
  line-height: 0;
  left: 0;
  right: 0;
  z-index: 1; }
  .block-editor-rich-text__inline-toolbar ul.components-toolbar {
    box-shadow: 0 2px 10px rgba(25, 30, 35, 0.1), 0 0 2px rgba(25, 30, 35, 0.1); }

.block-editor-skip-to-selected-block {
  position: absolute;
  top: -9999em; }
  .block-editor-skip-to-selected-block:focus {
    height: auto;
    width: auto;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 23px 14px;
    background: #f1f1f1;
    color: #11a0d2;
    line-height: normal;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    text-decoration: none;
    outline: none;
    z-index: 100000; }
  body.admin-color-sunrise .block-editor-skip-to-selected-block:focus{
    color: #c8b03c; }
  body.admin-color-ocean .block-editor-skip-to-selected-block:focus{
    color: #a89d8a; }
  body.admin-color-midnight .block-editor-skip-to-selected-block:focus{
    color: #77a6b9; }
  body.admin-color-ectoplasm .block-editor-skip-to-selected-block:focus{
    color: #c77430; }
  body.admin-color-coffee .block-editor-skip-to-selected-block:focus{
    color: #9fa47b; }
  body.admin-color-blue .block-editor-skip-to-selected-block:focus{
    color: #d9ab59; }
  body.admin-color-light .block-editor-skip-to-selected-block:focus{
    color: #c75726; }

.block-editor-block-list__block .block-editor-url-input,
.components-popover .block-editor-url-input,
.block-editor-url-input {
  flex-grow: 1;
  position: relative;
  padding: 1px; }
  .block-editor-block-list__block .block-editor-url-input input[type="text"],
  .components-popover .block-editor-url-input input[type="text"],
  .block-editor-url-input input[type="text"] {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: 16px; }
    @media (min-width: 600px) {
      .block-editor-block-list__block .block-editor-url-input input[type="text"],
      .components-popover .block-editor-url-input input[type="text"],
      .block-editor-url-input input[type="text"] {
        width: 300px; } }
    @media (min-width: 600px) {
      .block-editor-block-list__block .block-editor-url-input input[type="text"],
      .components-popover .block-editor-url-input input[type="text"],
      .block-editor-url-input input[type="text"] {
        font-size: 13px; } }
    .block-editor-block-list__block .block-editor-url-input input[type="text"]::-ms-clear,
    .components-popover .block-editor-url-input input[type="text"]::-ms-clear,
    .block-editor-url-input input[type="text"]::-ms-clear {
      display: none; }
  .block-editor-block-list__block .block-editor-url-input.has-border input[type="text"],
  .components-popover .block-editor-url-input.has-border input[type="text"],
  .block-editor-url-input.has-border input[type="text"] {
    border: 1px solid #555d66;
    border-radius: 4px; }
  .block-editor-block-list__block .block-editor-url-input.is-full-width,
  .components-popover .block-editor-url-input.is-full-width,
  .block-editor-url-input.is-full-width {
    width: 100%; }
    .block-editor-block-list__block .block-editor-url-input.is-full-width input[type="text"],
    .components-popover .block-editor-url-input.is-full-width input[type="text"],
    .block-editor-url-input.is-full-width input[type="text"] {
      width: 100%; }
    .block-editor-block-list__block .block-editor-url-input.is-full-width__suggestions,
    .components-popover .block-editor-url-input.is-full-width__suggestions,
    .block-editor-url-input.is-full-width__suggestions {
      width: 100%; }
  .block-editor-block-list__block .block-editor-url-input .components-spinner,
  .components-popover .block-editor-url-input .components-spinner,
  .block-editor-url-input .components-spinner {
    position: absolute;
    right: 8px;
    top: 9px;
    margin: 0; }

.block-editor-url-input__suggestions {
  max-height: 200px;
  transition: all 0.15s ease-in-out;
  padding: 4px 0;
  width: 302px;
  overflow-y: auto; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-url-input__suggestions {
      transition-duration: 0s; } }

.block-editor-url-input__suggestions,
.block-editor-url-input .components-spinner {
  display: none; }
  @media (min-width: 600px) {
    .block-editor-url-input__suggestions,
    .block-editor-url-input .components-spinner {
      display: inherit; } }

.block-editor-url-input__suggestion {
  padding: 4px 8px;
  color: #6c7781;
  display: block;
  font-size: 13px;
  cursor: pointer;
  background: #fff;
  width: 100%;
  border: none;
  text-align: left;
  border: none;
  box-shadow: none; }
  .block-editor-url-input__suggestion:hover {
    background: #e2e4e7; }
  .block-editor-url-input__suggestion:focus, .block-editor-url-input__suggestion.is-selected {
    background: rgb(0, 113, 158);
    color: #fff;
    outline: none; }
  body.admin-color-sunrise .block-editor-url-input__suggestion:focus, body.admin-color-sunrise .block-editor-url-input__suggestion.is-selected{
    background: rgb(178, 114, 63); }
  body.admin-color-ocean .block-editor-url-input__suggestion:focus, body.admin-color-ocean .block-editor-url-input__suggestion.is-selected{
    background: rgb(139, 157, 138); }
  body.admin-color-midnight .block-editor-url-input__suggestion:focus, body.admin-color-midnight .block-editor-url-input__suggestion.is-selected{
    background: rgb(191, 65, 57); }
  body.admin-color-ectoplasm .block-editor-url-input__suggestion:focus, body.admin-color-ectoplasm .block-editor-url-input__suggestion.is-selected{
    background: rgb(142, 155, 73); }
  body.admin-color-coffee .block-editor-url-input__suggestion:focus, body.admin-color-coffee .block-editor-url-input__suggestion.is-selected{
    background: rgb(165, 141, 119); }
  body.admin-color-blue .block-editor-url-input__suggestion:focus, body.admin-color-blue .block-editor-url-input__suggestion.is-selected{
    background: rgb(111, 153, 173); }
  body.admin-color-light .block-editor-url-input__suggestion:focus, body.admin-color-light .block-editor-url-input__suggestion.is-selected{
    background: rgb(0, 113, 158); }

.components-toolbar > .block-editor-url-input__button {
  position: inherit; }

.block-editor-url-input__button .block-editor-url-input__back {
  margin-right: 4px;
  overflow: visible; }
  .block-editor-url-input__button .block-editor-url-input__back::after {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    height: 24px;
    right: -1px;
    background: #e2e4e7; }

.block-editor-url-input__button-modal {
  box-shadow: 0 3px 30px rgba(25, 30, 35, 0.1);
  border: 1px solid #e2e4e7;
  background: #fff; }

.block-editor-url-input__button-modal-line {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  align-items: flex-start; }
  .block-editor-url-input__button-modal-line .components-button {
    flex-shrink: 0;
    width: 36px;
    height: 36px; }

.block-editor-url-popover__additional-controls {
  border-top: 1px solid #e2e4e7; }

.block-editor-url-popover__additional-controls > div[role="menu"] .components-icon-button:not(:disabled):not([aria-disabled="true"]):not(.is-default) > svg {
  box-shadow: none; }

.block-editor-url-popover__additional-controls div[role="menu"] > .components-icon-button {
  padding-left: 2px; }

.block-editor-url-popover__row {
  display: flex; }

.block-editor-url-popover__row > :not(.block-editor-url-popover__settings-toggle) {
  flex-grow: 1; }

.block-editor-url-popover .components-icon-button {
  padding: 3px; }
  .block-editor-url-popover .components-icon-button > svg {
    padding: 5px;
    border-radius: 4px;
    height: 30px;
    width: 30px; }
  .block-editor-url-popover .components-icon-button:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover {
    box-shadow: none; }
    .block-editor-url-popover .components-icon-button:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover > svg {
      color: #555d66;
      box-shadow: inset 0 0 0 1px #555d66, inset 0 0 0 2px #fff; }
  .block-editor-url-popover .components-icon-button:not(:disabled):focus {
    box-shadow: none; }
    .block-editor-url-popover .components-icon-button:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 1px #555d66, inset 0 0 0 2px #fff;
      outline: 2px solid transparent;
      outline-offset: -2px; }

.block-editor-url-popover__settings-toggle {
  flex-shrink: 0;
  border-radius: 0;
  border-left: 1px solid #e2e4e7;
  margin-left: 1px; }
  .block-editor-url-popover__settings-toggle[aria-expanded="true"] .dashicon {
    transform: rotate(180deg); }

.block-editor-url-popover__settings {
  display: block;
  padding: 16px;
  border-top: 1px solid #e2e4e7; }
  .block-editor-url-popover__settings .components-base-control:last-child,
  .block-editor-url-popover__settings .components-base-control:last-child .components-base-control__field {
    margin-bottom: 0; }

.block-editor-url-popover__link-editor,
.block-editor-url-popover__link-viewer {
  display: flex; }

.block-editor-url-popover__link-viewer-url {
  margin: 7px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 150px;
  max-width: 500px; }
  .block-editor-url-popover__link-viewer-url.has-invalid-link {
    color: #d94f4f; }

.block-editor-warning {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: #fff;
  border: 1px solid #e2e4e7;
  text-align: left;
  padding: 20px; }
  .has-warning.is-multi-selected .block-editor-warning {
    background-color: transparent; }
  .is-selected .block-editor-warning {
    border-color: rgba(66, 88, 99, 0.4);
    border-left-color: transparent; }
    .is-dark-theme .is-selected .block-editor-warning {
      border-color: rgba(255, 255, 255, 0.45); }
  .block-editor-warning .block-editor-warning__message {
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 13px; }
  .block-editor-warning .block-editor-warning__contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%; }
  .block-editor-warning .block-editor-warning__actions {
    display: flex; }
  .block-editor-warning .block-editor-warning__action {
    margin: 0 6px 0 0; }

.block-editor-warning__secondary {
  margin: 3px 0 0 -4px; }
  .block-editor-warning__secondary .components-icon-button {
    width: auto;
    padding: 8px 2px; }
  @media (min-width: 600px) {
    .block-editor-warning__secondary {
      margin-left: 4px; }
      .block-editor-warning__secondary .components-icon-button {
        padding: 8px 4px; } }
  .block-editor-warning__secondary .components-button svg {
    transform: rotate(90deg); }

.block-editor-writing-flow {
  height: 100%;
  display: flex;
  flex-direction: column; }

.block-editor-writing-flow__click-redirect {
  flex-basis: 100%;
  cursor: text; }

.html-anchor-control .components-external-link {
  display: block;
  margin-top: 8px; }
